import React, { useRef } from 'react';
import {
  DemoHeader,
  DemoFooter,
  DemoHeaderBilling,
  DemoFooterBilling,
  GeneflowLabHeader,
  GeneflowLabHeaderBilling,
  GeneflowLabFooter,
  GeneflowLabFooterBilling,
  AarvakDiagnosticCenterHeader,
  AarvakDiagnosticCenterFooter,
} from '@/features/report-builder/report-template/components/molecules/pdf/company';
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode';
import { Document } from '@react-pdf/renderer';
import {
  PdfTemp0001,
  PdfTemp0002,
  PdfTemp0003,
  PdfTemp0004,
  PdfTemp0005,
  PdfTemp0006,
  PdfTemp0007,
  PdfTemp0008,
  PdfTemp0009,
  PdfTemp0010,
} from '@/features/report-builder/report-template/components';
import { stores } from '@/stores';

import _ from 'lodash';

export const getHeaderAndFooter = (companyCode, details) => {
  switch (companyCode) {
    case 'DEMO':
      return {
        header: <DemoHeader data={details} />,
        footer: <DemoFooter data={details} />,
      };
    case 'GENEFLOW':
      return {
        header: <GeneflowLabHeader data={details} />,
        footer: <GeneflowLabFooter data={details} />,
      };
    case 'COMP0001':
      return {
        header: <AarvakDiagnosticCenterHeader />,
        footer: <AarvakDiagnosticCenterFooter />,
      };
    default:
      break;
  }
};

export const getHeaderAndFooterBilling = (companyCode, details) => {
  switch (companyCode) {
    case 'DEMO':
      return {
        header: <DemoHeaderBilling data={details} />,
        footer: <DemoFooterBilling />,
      };
    case 'GENEFLOW':
      return {
        header: <GeneflowLabHeaderBilling data={details} />,
        footer: <GeneflowLabFooterBilling />,
      };
    case 'COMP0001':
      return {
        header: <AarvakDiagnosticCenterHeader />,
        footer: <AarvakDiagnosticCenterFooter />,
      };
    default:
      break;
  }
};

export const getBarCode = (variable: any = 'https://www.limsplus.co.in') => {
  let canvas: any = '';
  canvas = document.createElement('canvas');
  JsBarcode(canvas, variable?.toString(), {
    lineColor: '#000',
    displayValue: true,
    height: 30,
  });
  return canvas.toDataURL();
};

export const getQRCode = (variable: any) => {
  let canvas: any = '';
  canvas = document.createElement('canvas');
  QRCode.toCanvas(canvas, variable?.toString());
  return canvas.toDataURL();
};

export const getReports = (
  reports,
  templateDetails,
  isWithHeader: boolean = true,
  isEmptyHeaderFooterSpace = false,
  companyCode = stores.loginStore.login.companyCode,
) => {
  const documentTitle = 'Delivery Queue';
  console.log({ companyCode });

  return (
    <Document title={documentTitle}>
      {reports?.map(item => (
        <>
          {item.template == 'TEMP0001' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0001
                  key={i}
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          item =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0001',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      item => item.templateCode == 'TEMP0001',
                    ),
                  }}
                  isWithHeader={isWithHeader}
                  isEmptyHeaderFooterSpace={isEmptyHeaderFooterSpace}
                />
              ),
            )}

          {item.template == 'TEMP0002' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0002
                  key={i}
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          item =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0002',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      item => item.templateCode == 'TEMP0002',
                    ),
                  }}
                  isWithHeader={isWithHeader}
                  isEmptyHeaderFooterSpace={isEmptyHeaderFooterSpace}
                />
              ),
            )}
          {item.template == 'TEMP0003' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0003
                  key={i}
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          item =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0003',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      item => item.templateCode == 'TEMP0003',
                    ),
                  }}
                  isWithHeader={isWithHeader}
                  isEmptyHeaderFooterSpace={isEmptyHeaderFooterSpace}
                />
              ),
            )}
          {item.template == 'TEMP0004' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0004
                  key={i}
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          item =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0004',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      item => item?.templateCode == 'TEMP0004',
                    ),
                  }}
                  isWithHeader={isWithHeader}
                  isEmptyHeaderFooterSpace={isEmptyHeaderFooterSpace}
                  companyCode={companyCode}
                />
              ),
            )}
          {item.template == 'TEMP0005' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0005
                  key={i}
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          item =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0005',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      item => item.templateCode == 'TEMP0005',
                    ),
                  }}
                  isWithHeader={isWithHeader}
                  isEmptyHeaderFooterSpace={isEmptyHeaderFooterSpace}
                />
              ),
            )}
          {item.template == 'TEMP0006' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0006
                  key={i}
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          item =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0006',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      item => item.templateCode == 'TEMP0006',
                    ),
                  }}
                  isWithHeader={isWithHeader}
                  isEmptyHeaderFooterSpace={isEmptyHeaderFooterSpace}
                />
              ),
            )}
          {item.template == 'TEMP0007' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0007
                  key={i}
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          item =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0007',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      item => item.templateCode == 'TEMP0007',
                    ),
                  }}
                  isWithHeader={isWithHeader}
                  isEmptyHeaderFooterSpace={isEmptyHeaderFooterSpace}
                />
              ),
            )}
          {item.template == 'TEMP0008' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0008
                  key={i}
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          item =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0008',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      item => item.templateCode == 'TEMP0008',
                    ),
                  }}
                  isWithHeader={isWithHeader}
                  isEmptyHeaderFooterSpace={isEmptyHeaderFooterSpace}
                  companyCode={companyCode}
                />
              ),
            )}
          {item.template == 'TEMP0009' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0009
                  key={i}
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          item =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0009',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      item => item?.templateCode == 'TEMP0009',
                    ),
                  }}
                  isWithHeader={isWithHeader}
                  isEmptyHeaderFooterSpace={isEmptyHeaderFooterSpace}
                  companyCode={companyCode}
                />
              ),
            )}
          {item.template == 'TEMP0010' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0010
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          item =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0010',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      item => item?.templateCode == 'TEMP0010',
                    ),
                  }}
                  isWithHeader={isWithHeader}
                  isEmptyHeaderFooterSpace={isEmptyHeaderFooterSpace}
                  companyCode={companyCode}
                />
              ),
            )}
        </>
      ))}
    </Document>
  );
};

export const reportList = data => {
  if (data) {
    const newObj = _.mapKeys(data, (value, key) => key.split(' -')[0]);
    let arrReportList: any = [];
    for (const values of Object.entries(newObj)) {
      let templateReportOrder = 0;
      values[1].map(item =>
        item?.patientResultList?.map(e => {
          if (e.reportTemplate?.split(' - ')[0] == values[0]) {
            templateReportOrder = e.reportTemplateOrder;
          }
        }),
      );
      arrReportList.push({
        template: values[0],
        patientReports: values[1],
        order: templateReportOrder,
      });
    }
    arrReportList = _.orderBy(arrReportList, 'order', 'asc');
    return arrReportList;
  }
};
